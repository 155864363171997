(function ($) {
  $(window).load(function () {
    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: true,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: true,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });

    // scroll-to-top
    $('.scroll-to-top').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $("html, body").stop().animate({scrollTop: 0}, 500, 'swing');
    });

  });

  $(document).ready(function () {

    // navbar-toggler
    $(document).on('click', '.navbar-toggler', function() {
      $('body').toggleClass('navbar-collapse-active');
    });

    $(window).on('scroll load', function () {
      if ($(window).scrollTop() > $(window).height()) {
        $('body').addClass('windowScrolled');
      } else {
        $('body').removeClass('windowScrolled');
      }
    });

    // sticky
    $(window).scroll(function () {
      var windowWidth = $(window).width();
      var $el = $('body').add('.header');
      if (windowWidth < 0) {
        $el.removeClass('sticky');
        return;
      }

      if ($(window).scrollTop() > 0) {
        $el.addClass('sticky');
      } else {
        $el.removeClass('sticky');
      }
    });

    // fancybox
    $('a[data-fancybox]').fancybox({
      buttons: [
        //"zoom",
        //"share",
        //"slideShow",
        //"fullScreen",
        //"download",
        "thumbs",
        "close"
      ]
    });

  });

  // white space padding
  $(".polaroid").parent(".bottom-space").css("margin-bottom", "400px");

  // Element animation
  // Remove the transition class
  const square = document.querySelector('.img-fluid');
  square.classList.remove('square-transition');

  // Create the observer, same as before:
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        square.classList.add('square-transition');
        return;
      }

      square.classList.remove('square-transition');
    });
  });

  observer.observe(document.querySelector('.image-floating'));

})(jQuery);
